import { DateTime } from "ts-luxon";

// Utility function to format date in American format (MM-DD-YYYY)
export const FormatDate = (dateString: string) => {
    const date = new Date(dateString); // Create a Date object from the dateString
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month and add 1 (zero-indexed)
    const day = date.getDate().toString().padStart(2, '0'); // Get day
    const year = date.getFullYear(); // Get year
    return `${month}-${day}-${year}`; // Return formatted string
};


// Utility function to format date in American format (Month DD, YYYY)
export const FormatDateString = (date: Date) => {
    if (date) {
        const etDate = new Date(date?.toLocaleString("en-US", { timeZone: "America/New_York" }));
        return etDate?.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short', // Three letter abbreviation month name (e.g., Oct)
            day: '2-digit' // Day with leading zero if needed
        });
    }
};

// Utility function to format Day
export const FormatDay = (date: Date) => {
    const etDate = new Date(date.toLocaleString("en-US", { timeZone: "America/New_York" }));
    const day = etDate.toLocaleDateString(undefined, { weekday: 'long' });
    return day.charAt(0).toUpperCase() + day.slice(1);
};

// Utility function to format time in AM/PM exactly as given in the input
export const FormatTime = (dateString: string) => {
    const date = new Date(dateString);
    let hours = date.getHours(); // Directly use the hour as given
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;  // Convert to 12-hour format
    return `${hours}:${minutes} ${ampm}`;
};


// Utility function to get initials of processor name
export const GetInitials = (name: string) => {
    return name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('');
};

// Utility function to format status
export const FormatStatus = (status: string, current_task?: string) => {
    if (status === "in_progress" && current_task === '') {
        status = "scheduled";
    }
    return status?.replace('_', ' ')?.replace(/\b\w/g, char => char.toUpperCase());
};

export const FormatName = (str: string) => {
    return str
        ?.split('_')
        ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
}

// Utility function to get current time in ET
export const GetETTime = (): string => {
    const now = new Date();
    const etTime = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
    const padZero = (num: number) => (num < 10 ? '0' + num : num);

    const year = etTime.getFullYear();
    const month = padZero(etTime.getMonth() + 1);
    const date = padZero(etTime.getDate());
    const hours = padZero(etTime.getHours());
    const minutes = padZero(etTime.getMinutes());
    const seconds = padZero(etTime.getSeconds());

    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
};

// Function to calculate the remaining time in UTC
export function CalculateRemainingTime(startTimeStr: string, allocatedTimeInSeconds: number): string {
    // Step 1: Convert the given ET time string to a Luxon DateTime object in the 'America/New_York' time zone
    const etTime: DateTime = DateTime.fromISO(startTimeStr, { zone: 'America/New_York' });

    // Step 2: Convert the ET time to UTC
    const utcTime: DateTime = etTime.toUTC();

    // Step 3: Get the current time in UTC
    const currentUtcTime: DateTime = DateTime.utc();

    // Step 4: Calculate the difference between the current UTC time and the converted ET time in seconds
    const secondsDifference = currentUtcTime.diff(utcTime, 'seconds').seconds;
    const remainingTimeInSeconds = allocatedTimeInSeconds - secondsDifference;
    const clampedRemainingTime = Math.max(0, remainingTimeInSeconds);

    // Step 5: Convert remaining time to hours, minutes, and seconds
    const hours = Math.floor(clampedRemainingTime / 3600);
    const minutes = Math.floor((clampedRemainingTime % 3600) / 60);
    const seconds = Math.floor(clampedRemainingTime % 60);

    return `${hours}h:${minutes}m:${seconds}s`;
}

// Function to calculate the completed time in ET
export function CalculateCompletedTime(startTimeStr: string, endTimeStr: string): string {
    const startTime = new Date(new Date(startTimeStr).toLocaleString("en-US", { timeZone: "America/New_York" }));
    const endTime = new Date(new Date(endTimeStr).toLocaleString("en-US", { timeZone: "America/New_York" }));
    const timeDifferenceInSeconds = Math.floor(endTime.getTime() / 1000 - startTime.getTime() / 1000);
    const clampedTimeDifference = Math.max(0, timeDifferenceInSeconds);

    const hours = Math.floor(clampedTimeDifference / 3600);
    const minutes = Math.floor((clampedTimeDifference % 3600) / 60);
    const seconds = clampedTimeDifference % 60;

    return `Finished in ${hours}h:${minutes}m:${seconds}s`;
}

export const CalculateElapsedTime = (startTime: string) => {
    if (startTime == "" || !startTime) {
        return ""
    }

    const etTime = DateTime.fromISO(startTime, { zone: 'America/New_York' });

    // Convert the ET time to UTC
    const utcTime = etTime.toUTC();

    // Get the current time in UTC
    const currentUtcTime = DateTime.utc();

    // Calculate the difference between the current UTC time and the converted ET time in seconds
    const secondsDifference = currentUtcTime.diff(utcTime, 'seconds').seconds;

    const clampedRemainingTime = Math.max(0, secondsDifference);

    // Convert remaining time to hours, minutes, and seconds
    const hours = Math.floor(clampedRemainingTime / 3600);
    const minutes = Math.floor((clampedRemainingTime % 3600) / 60);
    const seconds = Math.floor(clampedRemainingTime % 60);

    return `${hours}h:${minutes}m:${seconds}s`;
}

// Utility function to get and parse session storage data with fallback to default value
export const getSessionStorageData = (key: string, defaultValue = false) => {
    const storedValue = sessionStorage.getItem(key) || ''
    try {
        return JSON.parse(storedValue)
    } catch (e) {
        // If parsing fails, fallback to the default value
        return defaultValue
    }
}