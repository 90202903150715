import { createSlice } from "@reduxjs/toolkit";


// Initial state
const initialState = {
    notifications: [], // Single global state to hold all workflows
    loadingNotifications: false,
    error: null,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        fetchNotificationsStart: (state) => {
            state.loadingNotifications = true; // Set loading to true when the fetch starts
        },
        // Action to update notifications from SSE data
        updateNotifications: (state, action) => {
            const updatedNotifications = action.payload;
            if (updatedNotifications.length > 0) {
                updatedNotifications.forEach((updatedNotification) => {
                    const existingNotificationIndex = state.notifications.findIndex(
                        (notification) => notification.id === updatedNotification.id
                    );
                    if (existingNotificationIndex === -1) {
                        state.notifications.push(updatedNotification);
                        state.notifications.sort((a, b) => b.id - a.id);  // Sort descending by `id` if needed
                    }
                });
            }
        },
        fetchNotificationsSuccess: (state, action) => {
            state.notifications = action.payload; // Store all notifications in the global state
            state.loadingNotifications = false;
        },
        fetchNotificationsFailure: (state, action) => {
            state.loadingNotifications = false;
            state.error = action?.payload;
        }
    },

});

// Export actions and reducer
export const { updateNotifications, fetchNotificationsSuccess, fetchNotificationsFailure, fetchNotificationsStart } = notificationsSlice.actions;
export default notificationsSlice.reducer;