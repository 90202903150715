import { CalculateRemainingTime, FormatStatus, FormatTime, getSessionStorageData } from "helpers/Formatters/Format"
import { useEffect, useState } from "react"
import loadingIcon from "../../../../assets/images/loading.png"

export const TaskData = ({ taskData, setOpenTab }: any) => {
    const [remainingTime, setRemainingTime] = useState<string>('')

    // Get the disabled, failed, and escalate states
    const isDisabled = getSessionStorageData(`started_${taskData?.id}`)
    const isFailed = getSessionStorageData(`restarted_${taskData?.id}`)
    const isRestarted = getSessionStorageData(`escalated_${taskData?.id}`)

    // Update remaining time every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (taskData?.start_time != "" && taskData?.allocated_time != 0) {
                const newRemainingTime = CalculateRemainingTime(taskData?.start_time, taskData?.allocated_time)
                setRemainingTime(newRemainingTime)
            }
        }, 1000) // Update every second

        // Clear interval on component unmount
        return () => clearInterval(intervalId)
    }, [taskData])

    // Helper to render status badges
    const renderStatusBadge = (status: string) => {
        const statusMap: Record<string, string> = {
            ready: "info",
            completed: "success",
            in_progress: "warning",
            pending: "warning",
            failed: "danger",
            escalated: "info"
        }

        const color = statusMap[status] || "info"
        return (
            <span className={`badge bg-${color}-subtle border-2 border-${color} text-${color} capitalize`}>
                {FormatStatus(status)}
            </span>
        )
    }

    // Helper to render task icons
    const renderTaskIcon = (taskName: string, status: string) => {
        const iconMap: Record<string, Record<string, { icon: string, bgColor: string, iconColor: string }>> = {
            create_bundle: {
                completed: { icon: 'ri-folder-line', bgColor: '#0AB39C', iconColor: 'text-light' },
                default: { icon: 'ri-folder-line', bgColor: 'white', iconColor: 'text-[#0AB39C]' },
            },
            approve_create_bundle: {
                completed: { icon: ' ri-check-line', bgColor: '#0AB39C', iconColor: 'text-light' },
                default: { icon: ' ri-check-line', bgColor: 'white', iconColor: 'text-[#0AB39C]' },
            },
            upload_payment_bundle: {
                completed: { icon: ' ri-folder-upload-line', bgColor: '#0AB39C', iconColor: 'text-light' },
                default: { icon: ' ri-folder-upload-line', bgColor: 'white', iconColor: 'text-[#0AB39C]' },
            },
            upload_bundle: {
                completed: { icon: ' ri-check-line', bgColor: '#0AB39C', iconColor: 'text-light' },
                default: { icon: ' ri-check-line', bgColor: 'white', iconColor: 'text-[#0AB39C]' },
            },
        }

        // If task exists in iconMap, return the corresponding icon otherwise, return a default icon
        const taskIcons = iconMap[taskName] || {}
        const statusIcon = taskIcons[status] || taskIcons['default'] || { icon: 'ri-question-line', bgColor: 'gray-500', iconColor: 'text-white' }

        return (
            <div className={`avatar-title rounded-circle fs-14]`} style={{ backgroundColor: statusIcon.bgColor }}>
                <i className={`${statusIcon.icon} ${statusIcon.iconColor}`}></i>
            </div>
        )
    }


    // Helper to render action buttons
    const renderActionButton = () => {
        const { key_name, status, id, current_action, escalation_path } = taskData || {}
        const buttonConfig: any = {
            "create_bundle:ready": {
                label: "Start",
                actionKeyName: key_name,
                actionName: current_action?.display_name
            },
            "approve_create_bundle:ready": {
                label: "Approve",
                actionKeyName: key_name,
                actionName: current_action?.display_name
            },
            "upload_payment_bundle:ready": {
                label: "Start",
                actionKeyName: key_name,
                actionName: current_action?.display_name
            },
            "upload_bundle:ready": {
                label: "Approve",
                actionKeyName: key_name,
                actionName: current_action?.display_name
            },
            "create_bundle:failed": {
                label: "Restart",
                actionKeyName: "restart_create_bundle",
                actionName: "Restart Create Bundle"
            },
            "upload_payment_bundle:failed": {
                label: "Restart",
                actionKeyName: "restart_upload_payment_bundle",
                actionName: "Restart Upload Payment Bundle"
            },
        }

        const key = `${key_name}:${status}`
        const config = buttonConfig[key]

        
        if (config && (config.condition === undefined || config.condition)) {
            return (
                <button
                    className={`shadow-inner-all-sides text-sm font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] 
                        ${(isFailed && isDisabled && isRestarted) ? 'bg-[#355c72] text-gray-300' : 'animate-pulse text-white bg-[#31506A] active:bg-[#5483a9]'}
                        `}
                    onClick={() => setOpenTab({
                        taskId: id,
                        taskName: config.displayName,
                        actionName: config.actionName,
                        actionPath: current_action?.action_path,
                        esclationUrl: escalation_path,
                        actionKeyName: config.actionKeyName,
                        open: true,
                        label: config.label
                    })}
                    hidden={(status =="failed" && isFailed) ||
                        (status =="ready" && isDisabled )|| isRestarted}
                    disabled={(status =="failed" && isFailed ) || (status =="ready" && isDisabled)  || isRestarted}
                >
                    {config.label}
                </button>
            )
        }
        return null
    }

    return (
        <>
            <tr>
                <td className="p-0 h-8">
                    <div className="inset-0 ml-3 h-3/4 rounded-l-lg bg-light border-b-8 border-b-[#DAF4F0]">
                        <div className="flex items-center justify-center h-full">
                            <div className="flex items-center justify-start gap-2 col-span-2 w-full pl-3 py-3">
                                <div className="flex-shrink-0 avatar-xs align-self-start">
                                    {renderTaskIcon(taskData?.key_name, taskData?.status)}
                                </div>
                                <h6 className="font-semibold p-0 m-0">
                                    {taskData?.display_name?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                </h6>
                            </div>
                        </div>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className="h-3/4 bg-light border-b-8 border-b-[#DAF4F0] flex items-center">
                        <h6 className="px-4 m-0 ">
                            {taskData?.id}
                        </h6>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className='border-b-8 h-3/4 border-b-[#DAF4F0] bg-light flex items-center'>
                        <h6 className="px-4 m-0">{taskData?.start_time ? FormatTime(taskData?.start_time) : '-'}</h6>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className='border-b-8 h-3/4 border-b-[#DAf4F0] bg-light py-4 flex items-center'>
                        <h6 className="px-4 m-0"> {taskData?.end_time ? FormatTime(taskData?.end_time) : '-'}</h6>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className='border-b-8 h-3/4 border-b-[#DAf4F0] bg-light py-4 flex items-center'>
                        <h6 className="px-4 m-0">{taskData?.start_time ?
                            (taskData?.status.toLowerCase() !== "completed" ?
                                remainingTime :
                                '-'
                            ) :
                            '-'}
                        </h6>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className='border-b-8 h-3/4 border-b-[#DAF4F0] bg-light py-4 flex items-center'>
                        <h6 className="px-4 m-0">{
                            taskData?.status.toLowerCase() == "in_progress" ||
                                taskData?.isLoading ||
                                (isFailed && taskData?.status.toLowerCase() === "failed") ||
                                ((isDisabled || isRestarted) && taskData?.status.toLowerCase() == "ready") ?
                                <div className="w-full h-full">
                                    <img src={loadingIcon} className=" animate-spin h-6" />
                                </div>
                                : renderStatusBadge(taskData?.status)
                        }</h6>
                    </div>
                </td>
                <td className="p-0 m-0 h-8">
                    <div className='mr-3 border-b-8 h-3/4 border-b-[#DAF4F0] bg-light py-4 rounded-r-lg flex items-center'>
                        {renderActionButton()}
                    </div>
                </td>
            </tr>
        </>
    )
}
