import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux for Tickets
import { fetchTickets } from 'slices/tickets/thunk'
// import reusable components
import BreadCrumb from "Components/Common/BreadCrumb";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
//import utilities
import { FormatDate, FormatTime} from "helpers/Formatters/Format";
// import page components
import { TicketListing} from "./Listing";

const Ticket = () => {
    document.title = "Title | TFS Processing App"

    const dispatch = useDispatch()

    const { tickets, loadingTickets } = useSelector((state) => state.Ticket)

    const [activeTab, setActiveTab] = useState(1);
    const [activeTickets, setActiveTickets] = useState([])
    const [completedTickets, setCompletedTickets] = useState([])

    useEffect(() => {
        dispatch(fetchTickets())
    }, [])

    useEffect(() => {
        if (tickets && !loadingTickets) {
            const { active, completed } = separateTicketsByStatus(tickets)

            setActiveTickets(active)
            setCompletedTickets(completed)
        }
    }, [loadingTickets, tickets])

    const separateTicketsByStatus = (response) => {
        const completed = []
        const active = []

        response?.forEach(ticket => {

            const formattedTickets = {
                ticketID: ticket?.ticket_id,
                workflowName: ticket?.workflow_name,
                workflowID: ticket?.workflow_id,
                taskName: ticket?.task_name,
                subject: ticket?.error?.subject,
                createAt: ticket?.created_at != "" ? FormatDate(ticket?.created_at) + " ," + FormatTime(ticket?.created_at) : "",
                status: ticket?.ticket_status,
                priority: ticket?.priority,
                url: ticket?.ticket_url
            }

            if (ticket?.ticket_status === "open") {
                active.push(formattedTickets)
            } else {
                completed.push(formattedTickets)
            }
        })

        return { active, completed }
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };



    return (<React.Fragment>
        {loadingTickets ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) :
        (<div className="page-content" id="processingContent">
            <Container fluid>
                <BreadCrumb title="Ticket" pageTitle="Ticket" action="/tickets" />
                <Row className='relative'>
                    <Col>
                        <Card>
                            <CardBody>
                                <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 1 })}
                                            onClick={() => toggle(1)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            ACTIVE
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 2 })}
                                            onClick={() => toggle(2)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            COMPLETED
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                        <TicketListing tickets={activeTickets} />
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <TicketListing tickets={completedTickets} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)}

    </React.Fragment>)
}

export default Ticket