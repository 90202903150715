import React, { useEffect, useRef, useState } from 'react'
import {
  Card, CardBody, CardHeader, Col, Container, Row
} from 'reactstrap'
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { useDispatch, useSelector } from 'react-redux'

// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useLocation } from 'react-router-dom'
import Timer from '../../../Components/Common/Timer'
import { GetStatiscs } from './Statistics'
import { TaskData } from './Task'
import { fetchTickets } from 'slices/tickets/thunk'
import { getStatisticsByWorkflowId } from 'slices/workflow/statistics/thunk'
import {
  FormatTime, FormatDate, getSessionStorageData
} from 'helpers/Formatters/Format'
import { TicketListing } from 'pages/Tickets/Listing'
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor'
import { fetchWorkflows } from 'slices/workflow/thunk'

const InboundPaymentProcessing = ({ data: propsData }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const state = location?.state || propsData

  const [stats, setStats] = useState([])
  const [tasks, setTasks] = useState([])
  const [workflowStatus, setWorkflowStatus] = useState("")
  const [ticketsData, setTicketsData] = useState([])
  const [openTab, setOpenTab] = useState({ open: false })
  const [openEscalation, setOpenEscalation] = useState({ open: false, disabled: false })
  const [startTime, setStartTime] = useState("")
  const { tickets, loadingTickets } = useSelector((state) => state.Ticket)
  const ticketTitle = useRef(null)
  const ticketSubject = useRef(null)

  document.title = `${state?.key?.name} | TFS Processing App`

  const { workflows, loading } = useSelector((state) => state.Workflow)
  const { statistics, statisticsLoading } = useSelector((state) => state.Statistics)

  // Utility to get workflow by ID
  const getWorkflowById = (workflows, targetId) => workflows?.find((workflow) => workflow?.id === targetId)

  // Utility to get tasks by workflow ID
  const getTasksByWorkflowId = (workflows, targetId) => {
    const workflow = getWorkflowById(workflows, targetId)
    return workflow ? workflow?.tasks : []
  }

  useEffect(() => {
    if (workflows && !loading) {
      const targetId = state?.key?.workflow_id
      const newTasks = getTasksByWorkflowId(workflows, targetId)

      // Only update tasks if they have changed
      if (JSON.stringify(newTasks) !== JSON.stringify(tasks)) {
        // Check if the previous task was completed
        // Array to hold tasks with a loading indicator flag
        const updatedTasks = newTasks.map((task, index) => {
          // Initialize the task with a loading flag set to false by default
          let updatedTask = { ...task, isLoading: false };

          // Check if the current task is 'pending' and the previous task is 'completed'
          if (task?.status === "pending" && index > 0 && newTasks[index - 1]?.status?.toLowerCase() === "completed") {
            updatedTask.isLoading = true;
          }

          const isFailed = getSessionStorageData(`restarted_${task?.id}`)
          const isEscalated = getSessionStorageData(`escalated_${task?.id}`)

          if (task?.status.toLowerCase() === "in_progress" && isFailed) {
            sessionStorage.setItem(`started_${task?.id}`, JSON.stringify(false))
            sessionStorage.setItem(`restarted_${task?.id}`, JSON.stringify(false))
          }

          if (task?.status.toLowerCase() === "pending" && isEscalated && index > 0) {
            sessionStorage.setItem(`started_${newTasks[index - 1]?.id}`, JSON.stringify(false))
            sessionStorage.setItem(`escalated_${task?.id}`, JSON.stringify(false))
          }

          return updatedTask;
        });

        setTasks(updatedTasks)

      }

      // Get Worklfow's Updated StartTime and Status
      const matchingWorkflow = getWorkflowById(workflows, targetId)
      if (matchingWorkflow) {
        setStartTime(matchingWorkflow?.start_time)
        setWorkflowStatus(matchingWorkflow?.status)
      }

      const foundTask = newTasks.find(task => task?.key_name === "create_bundle")
      if (foundTask && foundTask.status?.toLowerCase() === 'completed') {
        const foundTaskArray = Array.isArray(foundTask?.result) ? foundTask?.result : [foundTask?.result]
        setStats((prevStats) => (prevStats !== foundTaskArray ? foundTaskArray : prevStats))
      }
    }
  }, [workflows, loading, state])

  useEffect(() => {
    if (statistics && !statisticsLoading) {
      setStats(statistics)
    }
  }, [statistics, statisticsLoading])

  useEffect(() => {

    // Call Getstatistics API
    if (state) {
      const targetId = state?.key?.workflow_id
      const matchingWorkflow = getWorkflowById(workflows, targetId)

      dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
    }

    if (state?.key?.tasks) {

      const newTasks = state?.key?.tasks
      const updatedTasks = newTasks.map((task, index) => {
        // Initialize the task with a loading flag set to false by default
        let updatedTask = { ...task, isLoading: false };

        // Check if the current task is 'pending' and the previous task is 'completed'
        if (task?.status === "pending" && index > 0 && tasks[index - 1]?.status === "completed") {
          updatedTask.isLoading = true;
        }

        return updatedTask;
      });

      setTasks(updatedTasks)
    }
  }, [state])

  const handleStartButtonClick = async (taskId, actionPath, actionKeyName) => {
    setOpenTab({ open: false })

    // Save the disabled state to localStorage
    sessionStorage.setItem(`started_${taskId}`, JSON.stringify(true))

    const body = JSON.stringify({
      [actionKeyName === "create_bundle" ? "create_bundle_task_id"
        : actionKeyName === "approve_create_bundle" ? "approve_create_bundle_task_id"
          : actionKeyName === "upload_payment_bundle" ? "upload_bundle_task_id" : "approve_upload_bundle_task_id"]: taskId
    })

    await determineBaseUrlAndPerformApiCall(`${actionPath}`, 'POST', body).then((response) => {
      if (!response.error) {
        switch (actionKeyName) {
          case "create_bundle":
            toast.success("Workflow Started Sucessfully !", {
              position: "top-center"
            })
            break
          case "approve_create_bundle":
            toast.success("Bundle Approved Sucessfully !", {
              position: "top-center"
            })
            break
          case "upload_payment_bundle":
            toast.success("Upload Payment Bundle Started Successfully!", {
              position: "top-center"
            })
            break
          case "upload_bundle":
            toast.success("Payment Bundle Approved Sucessfully !", {
              position: "top-center"
            })
            break
        }
      }
      if (response.error) {
        toast.error(response.error.message, {
          position: "top-center"
        })
      }
    }).catch((errorResp) => {
      toast.error(errorResp, {
        position: "top-center"
      })
    })

  }

  const handleTaskRestartButtonClick = async (taskId, taskName) => {
    setOpenTab({ open: false })

    // Save the disabled state to localStorage
    sessionStorage.setItem(`restarted_${taskId}`, JSON.stringify(true))

    const pathUri = taskName === "restart_create_bundle" ? "restart_create_bundle_task" : "restart_upload_bundle_task"

    const body = JSON.stringify({
      [taskName === "restart_create_bundle" ? "create_bundle_task_id" : "upload_bundle_task_id"]: taskId
    })

    await determineBaseUrlAndPerformApiCall(`/inbound/${pathUri}`, 'POST', body).then((response) => {
      if (!response.error) {
        toast.success("Task Restarted Sucessfully !", {
          position: "top-center"
        })
      }
      if (response.error) {
        toast.error(response.error.message, {
          position: "top-center"
        })
      }
    }).catch((errorResp) => {
      toast.error(errorResp, {
        position: "top-center"
      })
    })
  }

  const handleTicketEsclations = async (esclationUrl, taskKeyName, taskId) => {
    if (ticketTitle?.current?.value === "") {
      toast.error("Title of the Ticket missing!", {
        position: "top-center"
      })
    }

    if (ticketSubject?.current?.value === "") {
      toast.error("Subject of the Ticket missing !", {
        position: "top-center"
      })
    }

    setOpenTab({ open: false });
    setOpenEscalation({ open: false })

    // Save the disabled state to localStorage
    const workflowId = state?.key?.workflow_id

    // Get the Task ID by looping the workflow
    sessionStorage.setItem(`escalated_${taskId}`, JSON.stringify(true))

    if (workflows) {// Loop the workflow and find matching workflow id
      const matchingWorkflows = workflows?.find((workflow) => workflow?.id === workflowId)

      const taskNameToLookFor = taskKeyName == "approve_create_bundle" ? "create_bundle" : "upload_payment_bundle"

      const task = matchingWorkflows?.tasks?.find((task) => task?.key_name === taskNameToLookFor)

      const taskIdKey =
        taskKeyName === "approve_create_bundle" ? "create_bundle_task_id" :
          taskKeyName === "upload_bundle" ? "upload_bundle_task_id" : ""

      const data = {
        [taskIdKey]: task?.id, // Ensure valid key
        ticket_title: ticketTitle?.current?.value,
        ticket_subject: ticketSubject?.current?.value,
      };

      await determineBaseUrlAndPerformApiCall(`${esclationUrl}`, 'POST', data).then((response) => {
        if (!response.error) {
          toast.success("Ticket raised successfully !", {
            position: "top-center"
          })
          // Reset the input fields
          ticketTitle.current.value = ""
          ticketSubject.current.value = ""
        }
        if (response.error) {
          toast.error(response.error.message, {
            position: "top-center"
          })
        }
      }).catch((errorResp) => {
        toast.error(errorResp, {
          position: "top-center"
        })
      })

    }
  }

  useEffect(() => {
    if (state?.key?.started_time != "") {
      setStartTime(state?.key?.started_time)
    }

    if (state?.key?.status !== "") {
      setWorkflowStatus(state?.key?.status)
    }


    if (state) {
      const targetId = state?.key?.workflow_id
      const matchingWorkflow = getWorkflowById(workflows, targetId)

      dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
    }

    if (state?.key?.tasks) {

      const newTasks = state?.key?.tasks
      const updatedTasks = newTasks.map((task, index) => {
        // Initialize the task with a loading flag set to false by default
        let updatedTask = { ...task, isLoading: false };

        // Check if the current task is 'pending' and the previous task is 'completed'
        if (task?.status === "pending" && index > 0 && tasks[index - 1]?.status === "completed") {
          updatedTask.isLoading = true;
        }

        return updatedTask;
      });

      setTasks(updatedTasks)
    }

    // Get Tickets and Filter them by workflow ID
    dispatch(fetchTickets())
    dispatch(fetchWorkflows())
  }, [])

  useEffect(() => {
    if (tickets && !loadingTickets) {

      const targetId = state?.key?.workflow_id

      const matchingTickets = ((tickets, targetId) => {
        return tickets.filter(ticket => ticket.workflow_id === targetId)
      })(tickets, targetId)


      const result = matchingTickets?.map((item => {
        return {
          ticketID: item?.ticket_id,
          workflowName: item?.workflow_name,
          workflowID: item?.workflow_id,
          taskName: item?.task_name,
          subject: item?.error?.subject,
          createAt: item?.created_at != "" ? FormatDate(item?.created_at) + " ," + FormatTime(item?.created_at) : "",
          status: item?.ticket_status,
          priority: item?.priority,
          url: item?.ticket_url
        }
      }))

      setTicketsData(result)
    }
  }, [loadingTickets, tickets, state])


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content" id="processingContent">
        <div
          className={`slider-content w-11/12 sm:w-3/6 md:5/12 lg:w-2/6 h-full transition-all duration-700 ${openEscalation.open ? 'translate-x-0 opacity-100' : ' opacity-0 translate-x-full'
            }`}
        >
          <div className='h-[75%] pl-3'>
            <div className='bg-white border-2 border-[#B6B6B6] py-2 h-full rounded-lg  mb-10'>
              <div className='flex gap-3 items-center px-4 py-2'>
                <div onClick={() => setOpenEscalation({ open: false })} className='cursor-pointer'>
                  <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
                </div>
                <p className='uppercase font-medium text-sm m-0'>{openEscalation.actionName}</p>
              </div>
              <hr className='mt-2 text-gray-500' />
              <div className='px-3 flex flex-col gap-2 bg-stone-100 rounded-xl mx-3 py-3'>
                <h6 className='uppercase font-semibold'>Create Ticket</h6>
                <input type="text" className="border-0 rounded px-3 py-2" placeholder="Title" ref={ticketTitle} />
                <textarea className="border-0 px-3 py-2" rows={5} placeholder="Subject" ref={ticketSubject}></textarea>
                {/* <input type=""/> */}
                <div className='flex justify-end gap-2 mt-2'>
                  <button
                    // disabled={JSON.parse(sessionStorage.getItem(`disable_escalate_${openTab?.id}`))}
                    className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                    onClick={() => setOpenEscalation({ open: false })}
                  >
                    Cancel
                  </button>
                  <button
                    className='bg-[#31506A] shadow-inner-all-sides text-xs text-white font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                    onClick={() => handleTicketEsclations(openEscalation?.esclationUrl, openEscalation?.taskKeyName, openEscalation?.id)}
                  >
                    Send
                  </button>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div
          className={`slider-content w-11/12 sm:w-3/6 md:5/12 lg:w-2/6 h-full transition-all duration-700 ${openTab.open ? 'translate-x-0 opacity-100' : ' opacity-0 translate-x-full'}`}
        >
          <div className='h-[75%] pl-3'>
            <div className='bg-white border-2 border-[#B6B6B6] py-2 h-full rounded-lg  mb-10'>
              <div className='flex gap-3 items-center px-4 py-2'>
                <div onClick={() => setOpenTab({ open: false })} className='cursor-pointer'>
                  <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
                </div>
                <p className='uppercase font-medium text-sm m-0'>{openTab.actionName}</p>
              </div>
              <hr className='mt-2 text-gray-500' />
              <div className='px-2 flex flex-col h-5/6 justify-between'>
                <div className='flex-col gap-4'>
                  <div className='flex-col gap-2 px-3'>
                    <div className='flex w-3/4 pb-2'>
                      <span className='font-semibold  w-1/2'>Task Name: </span>
                      <span>{openTab?.actionName}</span>
                    </div>
                    <div className='flex w-3/4 pb-2'>
                      <span className='font-semibold w-1/2'>Task ID: </span>
                      <span>{openTab?.taskId}</span>
                    </div>
                    <div className='flex w-3/4'>
                      <span className='font-semibold w-1/2'>Workflow ID: </span>
                      <span>{state?.key?.workflow_id}</span>
                    </div>
                  </div>
                  {tasks?.some(task => task?.key_name === "create_bundle" && task?.status === "completed") && (
                    <Card className='border-1 mt-4'>
                      <CardHeader>
                        <h5 className="card-title mb-0">Statistics</h5>
                      </CardHeader>
                      <CardBody className='text-xs'>
                        <GetStatiscs statistics={stats} />
                      </CardBody>
                    </Card>)
                  }
                </div>
                <div className='flex justify-end gap-2 mr-4'>
                  {openTab.actionKeyName === "approve_create_bundle" || openTab.actionKeyName === "upload_bundle" ?
                    <button
                      // disabled={openTab?.actionType == "Restart" ?
                      //   JSON.parse(sessionStorage.getItem(`disable_failed_${openTab?.id}`)) :
                      //   JSON.parse(sessionStorage.getItem(`disable_${openTab?.id}`))
                      // }
                      className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                      onClick={() => {
                        setOpenTab({ open: false })
                        setOpenEscalation({
                          open: true,
                          id: openTab.taskId,
                          taskKeyName: openTab?.actionKeyName,
                          esclationUrl: openTab?.esclationUrl,
                          actionName: openTab?.action_name
                        })
                      }}
                    >
                      Escalate
                    </button> :
                    <button
                      // disabled={openTab?.actionType == "Restart" ?
                      //   JSON.parse(sessionStorage.getItem(`disable_failed_${openTab?.id}`)) :
                      //   JSON.parse(sessionStorage.getItem(`disable_${openTab?.id}`))
                      // }
                      className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                      onClick={() => setOpenTab({ open: false })}
                    >
                      Cancel
                    </button>

                  }
                  <button
                    // disabled={openTab?.actionType == "Restart" ?
                    //   JSON.parse(sessionStorage.getItem(`disable_failed_${openTab?.id}`)) :
                    //   JSON.parse(sessionStorage.getItem(`disable_${openTab?.id}`))
                    // } 
                    className='bg-[#31506A] shadow-inner-all-sides text-xs text-white font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                    onClick={() => {
                      openTab.label === "Restart" ?
                        handleTaskRestartButtonClick(openTab.taskId, openTab.actionKeyName) :
                        handleStartButtonClick(openTab.taskId, openTab.actionPath, openTab.actionKeyName)
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container fluid>
          <BreadCrumb title={state?.key?.name} pageTitle="Workflow" action="/workflow" startTime={startTime} />
          {/* Workflow List */}
          <Row>
            <Col lg={12}>
              <Card className='border-none'>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-none">
                    <thead>
                      <tr>
                        <th colSpan="6" className="px-4 py-2 font-semibold text-left border-b border-b-gray-100">TASKS</th>
                        <th rowSpan="2" className=''>
                          <Timer startedTime={startTime} allocatedTime={state?.key?.allocated_time} status={workflowStatus} />
                        </th>
                      </tr>
                      <tr>
                        <th className="px-8 py-2 font-semibold">Task Name</th>
                        <th className="px-4 py-2  font-semibold">Task ID</th>
                        <th className="px-4 py-2 font-semibold">Start Time</th>
                        <th className="px-4 py-2  font-semibold">End Time</th>
                        <th className="px-4 py-2 font-semibold">Time Left</th>
                        <th className="px-4 py-2 font-semibold">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks?.map((task) => (
                        <TaskData key={task?.id} taskData={task} workflowStatus={workflowStatus} setOpenTab={setOpenTab} openTab={openTab} />
                      ))}
                    </tbody>

                  </table>
                </div>
              </Card>
            </Col>
          </Row>
          {/* Statistics */}
          {tasks?.some(task => task?.key_name === "create_bundle" && task?.status === "completed") && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">STATISTICS</h5>
                  </CardHeader>
                  <CardBody>
                    <GetStatiscs statistics={stats} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">TICKETS</h5>
                </CardHeader>
                <CardBody>
                  <TicketListing tickets={ticketsData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}

export default InboundPaymentProcessing
