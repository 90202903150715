import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// Redux for workflows
import { fetchWorkflows } from 'slices/workflow/thunk'
// import utilities
import {
    FormatName, FormatDate, FormatTime, FormatStatus, GetInitials
} from 'helpers/Formatters/Format'
// import resusable components
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
// import page components
import { WorkflowList } from './WorkflowList'
import { WorkflowHistory } from './WorkflowHistory'



const Workfow = () => {
    document.title = "Workflow | TFS Processing App"

    const dispatch = useDispatch()

    const { workflows, loading } = useSelector((state) => state.Workflow)

    const [completedWorkflows, setCompletedWorkflows] = useState([])
    const [inProgressWorkflows, setInProgressWorkflows] = useState([])

    useEffect(() => {
        // We call get workflows on page load
        dispatch(fetchWorkflows())
    }, [])

    useEffect(() => {
        // Whenever workflow data is changes, update the display data
        if (workflows && !loading) {
            const { completed, inProgress } = separateWorkflowsByStatus(workflows)

            setCompletedWorkflows(completed)
            setInProgressWorkflows(inProgress)
        }
    }, [loading, workflows])



    // TODO: Remove from here
    const separateWorkflowsByStatus = (response) => {
        const completed = []
        const inProgress = []

        response?.forEach(item => {
            const formattedWorkflow = {
                name: FormatName(item?.workflow_display_name),  // Convert workflow_name to title case
                date: item?.created_at !== "" ? FormatDate(item?.created_at) : "",   // Format start_time to MM-DD-YYYY
                start_time: item?.start_time !== "" ? FormatTime(item?.start_time) : "",  // Format time to AM/PM
                end_time: item?.end_time !== "" ? FormatTime(item?.end_time) : "",  // Format time to AM/PM
                processor: GetInitials(item?.processor_name),  // Get initials from processor_name
                current_Task: item?.current_running_task || '',  // Handle missing current_running_task
                status: item?.status !== "" ? FormatStatus(item?.status, item?.current_running_task) : "",
                tasks: item?.tasks,            // Format status
                allocated_time: item?.allocated_time,
                started_time: item?.start_time,
                workflow_id: item?.id
            }

            if (item?.status === 'completed') {
                completed.push(formattedWorkflow)
            } else {
                inProgress.push(formattedWorkflow)
            }
        })

        return { completed, inProgress }
    }

    return (
        <React.Fragment>
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) :
                (<div className="page-content" id="processingContent">
                    <Container fluid>
                        <BreadCrumb title="Workflow" pageTitle="Workflow" action="/workflow" />
                        {/* Workflow List */}

                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 capitalize">ACTIVE WORKFLOWS</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowList workflowData={inProgressWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* Workflow History */}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <h3 className="card-title mb-0 ">COMPLETED WORKFLOWS</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <WorkflowHistory workflowData={completedWorkflows} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>)}
        </React.Fragment>
    )
}

export default Workfow

